import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

export enum UserRole {
    customer = 'customer',
    employee = 'employee',
    consumer = 'consumer',
    unknown = 'unknown',
}

export interface UserInfo {
    loginId: string;
    role: UserRole;
    email: string;
    companyId: string;
    admin: boolean;
    iamRoles: Set<string>;
}

export interface SalesOrgInfo {
    salesOrgs: string[];
    primarySalesOrg?: string;
}

interface IAMUser {
    id: string;
    loginId: string;
    languageCode: string;
    contacts: {
        email: string;
    };
    name: {
        title: string;
        firstName: string;
        familyName: string;
    };
    address: {
        postalCode: string;
        city: string;
        street: string;
        countryCode: string;
    };
    properties?: {
        name: string;
        value: string;
    }[];
    applicationRoles: string[];
}

interface SalesUser {
    contactId: string;
    company: {
        name: string;
        vkorg: string;
        countryCode: string;
        id: string;
    };
}

// Declare interface for Google Analytics 4
declare global {
    interface Window {
        dataLayer: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    private userInfo$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(undefined);
    constructor(private http: HttpClient, private router: Router) {}

    public checkIsUserAuthorized(userInfo: UserInfo, embedded: boolean) {
        if (userInfo.role !== UserRole.employee && !userInfo.iamRoles.has(environment.app.roles.default)) {
            const queryParamOptions: any = {
                queryParamsHandling: 'merge',
            };
            if (embedded) {
                queryParamOptions.queryParams = {
                    embedded: embedded,
                };
            }
            this.router.navigate(['forbidden'], queryParamOptions);
            return false;
        }
        return true;
    }

    public getLoggedInUserInfo(): Observable<UserInfo> {
        if (this.userInfo$.value) {
            return of(this.userInfo$.value);
        }
        return this.fetchLoggedInUser<IAMUser & SalesUser>('identity', 'sales', 'roles').pipe(
            map((user) => {
                const identityProp = user.properties.find((p) => p.name === 'IdentityType');
                // Push user data for Google Analytics 4 dataLayer
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'user_profile_loaded',
                    identityType: identityProp.value,
                    userId: user.id,
                    userProfileLoaded: true,
                });
                return <UserInfo>{
                    loginId: user.loginId,
                    role: this.mapIdentityTypeToRole(identityProp?.value),
                    email: user.contacts?.email,
                    companyId: user.company?.id,
                    admin: user.applicationRoles?.includes(environment.app.roles.admin),
                    iamRoles: new Set(user.applicationRoles),
                };
            }),
            tap((userInfo: UserInfo) => this.userInfo$.next(userInfo))
        );
    }

    public fetchSalesOrgInfo(userInfo: UserInfo): Observable<SalesOrgInfo> {
        if (userInfo.role === UserRole.employee) {
            return this.http
                .get<{ salesFunctions: { salesOrg: string }[] }>(`${environment.http.apiBaseUrl}employee`)
                .pipe(map((res) => ({ salesOrgs: res.salesFunctions.map((sf) => sf.salesOrg) })));
        } else {
            return this.http
                .get<{ primarySalesOrg: string; salesOrgs: { salesOrg: string }[] }>(
                    `${environment.http.apiBaseUrl}customer/data?customer=${userInfo.companyId}`
                )
                .pipe(
                    map(({ primarySalesOrg, salesOrgs }) => ({
                        primarySalesOrg,
                        salesOrgs: salesOrgs.map((so) => so.salesOrg),
                    }))
                );
        }
    }

    public isUserEmployee(): Observable<boolean> {
        return this.getLoggedInUserInfo().pipe(map((userInfo) => this.isCurrentUserEmployee(userInfo)));
    }

    public isUserEndcustomer(): Observable<boolean> {
        return this.getLoggedInUserInfo().pipe(map((userInfo) => this.isCurrentUserEndcustomer(userInfo)));
    }

    public getCompanyId(): string {
        return this.userInfo$.value?.companyId;
    }

    public getCurrentUserRole(userInfo: UserInfo = this.userInfo$.value): UserRole {
        return userInfo?.role;
    }

    public isCurrentUserEmployee(userInfo: UserInfo = this.userInfo$.value): boolean {
        return userInfo?.role === UserRole.employee;
    }

    public isCurrentUserCustomer(userInfo: UserInfo = this.userInfo$.value): boolean {
        return userInfo?.role === UserRole.customer;
    }

    public isCurrentUserEndcustomer(userInfo: UserInfo = this.userInfo$.value): boolean {
        return userInfo?.role === UserRole.consumer;
    }

    public isCurrentUserAdmin(userInfo: UserInfo = this.userInfo$.value): boolean {
        return userInfo?.admin;
    }

    public isCurrentUserTechnician(userInfo: UserInfo = this.userInfo$.value): boolean {
        return (
            this.isCurrentUserCustomer(userInfo) &&
            environment.app.roles.ecommerce.some((r) => !userInfo?.iamRoles.has(r))
        );
    }

    private fetchLoggedInUser<T>(...sections: string[]): Observable<T> {
        let params = new HttpParams();
        sections.forEach((section) => {
            params = params.append('sections', section);
        });
        return this.http.get<T>(`${environment.http.users}me`, { params });
    }

    private mapIdentityTypeToRole(identityType: string): UserRole {
        switch (identityType) {
            case 'ViInt':
            case 'ViExt':
            case 'ViAcc':
                return UserRole.employee;
            case 'KuMA':
                return UserRole.customer;
            case 'EK':
                return UserRole.consumer;
            default:
                return UserRole.unknown;
        }
    }
}
