<div fxLayout="column">
    <div fxFlex="64px" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="10px" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="left center">
            <a fxLayout="row" fxLayoutAlign="start center" routerLink="/" class="app-title" fxLayoutGap="4px">
                <img src="../../../../assets/img/viessmann-white.svg" class="logo-img" />
                <div fxHide.lt-sm fxLayoutAlign="start center" fxLayoutGap="4px">
                    <span>&#65381;</span>
                    <span class="title d-none d-sm-block">{{ 'HEADER.APP_TITLE' | translate }}</span>
                </div>
            </a>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <app-language-dropdown
                [selectedCountry]="appService.getLocation()"
                (languageChanged)="onLanguageChanged($event)"
                *ngIf="this.appService.availableLanguages.length"
            >
            </app-language-dropdown>

            <button mat-button (click)="sendFeedback()">
                <mat-icon>mail</mat-icon>
                <span class="d-none d-md-inline">
                    {{ 'HEADER.BUTTON.FEEDBACK' | translate }}
                </span>
            </button>
            <a mat-icon-button [href]="faqLink" target="_blank">
                <mat-icon>help_outline</mat-icon>
            </a>
            <vih-app-switcher></vih-app-switcher>
            <vih-login [appManagesAuthentication]="true" [authenticated]="authenticated$"></vih-login>
        </div>
    </div>
</div>
